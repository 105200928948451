import {
  Box,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Button from "@mui/material/Button"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useTheme } from "@mui/material/styles"
import AccountCircle from "@mui/icons-material/AccountCircle"
import PublicIcon from "@mui/icons-material/Public"
import SettingsIcon from "@mui/icons-material/Settings"
import LogoutIcon from "@mui/icons-material/Logout"
import SpeedIcon from "@mui/icons-material/Speed"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useKeycloak } from "@react-keycloak/web"
import React, { useContext, useMemo } from "react"
import { useIntl } from "react-intl"
import { useLoggedInUser } from "./loggedInUserContext"
import { LunaMenu } from "./LunaMenu"
import { LunaMenuItem } from "./LunaMenuItem"
import { TimezoneID, useTimezone } from "./timezoneContext"
import { WindUnit, WindUnitContext } from "./WindUnitContext"

type Props = {
  handleLocaleChange: () => void
  type: "energy" | "offshore" | "preview"
}

export const SettingsMenu = ({ handleLocaleChange, type }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { keycloak } = useKeycloak()
  const { currentTimezone, setCurrentTimezone } = useTimezone()
  const intl = useIntl()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  const { activeLanguage } = useMemo(() => {
    return {
      activeLanguage: intl.locale == "nb" ? "Norsk" : "English",
    }
  }, [intl.locale])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAccountSettings = (event: React.MouseEvent<HTMLElement>) => {
    handleClose()
    if (keycloak) {
      keycloak.accountManagement()
    }
  }

  const handleClearLocalStorage = (event: React.MouseEvent<HTMLElement>) => {
    handleClose()
    window.localStorage.clear()
  }

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    handleClose()
    if (keycloak) {
      keycloak.logout()
    }
  }

  const { firstName, lastName } = useLoggedInUser()
  const { windUnit, handleWindUnitChange } = useContext(WindUnitContext)

  return (
    <div>
      {isDesktop ? (
        <Button
          color="secondary"
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          onClick={handleClick}
          endIcon={<SettingsIcon fontSize="small" />}
        >
          {intl.formatMessage({ id: "settings" })}
        </Button>
      ) : (
        <IconButton
          aria-label={intl.formatMessage({ id: "userMenu" })}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="secondary"
          size="large"
        >
          <AccountCircle />
        </IconButton>
      )}
      <LunaMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LunaMenuItem disableRipple>
          <ListItemIcon>
            <SpeedIcon fontSize="small" />
          </ListItemIcon>

          <ToggleButtonGroup
            color="primary"
            value={windUnit}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              newWindUnit: string
            ) => {
              newWindUnit && handleWindUnitChange(newWindUnit as WindUnit)
            }}
            aria-label={intl.formatMessage({ id: "changeSpeedUnit" })}
          >
            <ToggleButton
              value="KT"
              aria-label={intl.formatMessage({ id: "knots" })}
            >
              {intl.formatMessage({
                id: "knots",
              })}
            </ToggleButton>
            <ToggleButton
              value="m/s"
              aria-label={intl.formatMessage({ id: "metersPerSecond" })}
            >
              {intl.formatMessage({
                id: "m/s",
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        </LunaMenuItem>
        {type === "offshore" && (
          <LunaMenuItem disableRipple>
            <ListItemIcon>
              <AccessTimeIcon fontSize="small" />
            </ListItemIcon>

            <ToggleButtonGroup
              color="primary"
              value={currentTimezone}
              exclusive
              onChange={(
                _event: React.MouseEvent<HTMLElement>,
                newTimezone: string
              ) => {
                newTimezone && setCurrentTimezone(newTimezone as TimezoneID)
              }}
              aria-label={intl.formatMessage({ id: "changeTimezone" })}
            >
              <ToggleButton value="CET">
                {intl.formatMessage({ id: "localTime" })}
              </ToggleButton>
              <ToggleButton value="UTC">
                {intl.formatMessage({ id: "universalTime" })}
              </ToggleButton>
            </ToggleButtonGroup>
          </LunaMenuItem>
        )}
        <LunaMenuItem disableRipple>
          <ListItemIcon>
            <PublicIcon fontSize="small" />
          </ListItemIcon>
          <ToggleButtonGroup
            color="primary"
            value={activeLanguage}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              language: string
            ) => {
              language && handleLocaleChange()
            }}
            aria-label={intl.formatMessage({ id: "changeLanguage" })}
          >
            <ToggleButton value="Norsk">Norsk</ToggleButton>
            <ToggleButton value="English">English</ToggleButton>
          </ToggleButtonGroup>
        </LunaMenuItem>
        <LunaMenuItem disableRipple sx={{ paddingTop: 2 }}>
          <Typography>
            {intl.formatMessage({ id: "profile" })}: {firstName} {lastName}
          </Typography>
        </LunaMenuItem>
        <Divider />
        <LunaMenuItem onClick={handleAccountSettings}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "account_settings" })}
          />
        </LunaMenuItem>
        <Divider />
        <LunaMenuItem onClick={handleClearLocalStorage}>
          <ListItemText
            primary={intl.formatMessage({ id: "restore_default_settings" })}
          />
        </LunaMenuItem>
        <Divider />
        <LunaMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage({ id: "logout" })} />
        </LunaMenuItem>
      </LunaMenu>
    </div>
  )
}
