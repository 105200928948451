import * as Moment from "moment"
import {
  ChartBasis,
  OffshoreDataList,
  OffshoreForecastData,
  OffshoreSeriesKeys,
} from "../../../../@types/OffshoreGrapOption"
import { extendMoment } from "moment-range"
import { IntlShape } from "react-intl"
import {
  OFFSHORE_DATA_ELEMENTS,
  lunaColors,
  observedId,
  stack,
  yAxis,
} from "./graphStaticValues"
import {
  barbedOffset,
  getCompleteSeriesVisibility,
  getSwellDirectionVisibillity,
  getWaveDirectionVisibillity,
  getWindDirectionVisibillity,
  initialiseSeriesVisibility,
  xOffsetPlotLine,
  yOffsetWindPlotLine,
} from "./graphOptionsUtils"
import { capitalize, cloneDeep } from "lodash"
import {
  Chart,
  XAxisPlotLinesLabelOptions,
  XAxisPlotLinesOptions,
} from "highcharts"
import { convertToMetersPerSecond } from "@luna/luna-core"
import {
  createForecastOffshorePlots,
  createObservedOffshorePlots,
} from "./createOffshorePlots"

const moment = extendMoment(Moment)

// This might get messy.
export let getSeriesVisibility: (series: OffshoreSeriesKeys) => boolean

export const offshoreGraphOptions = (
  chartBasis: ChartBasis
): Highcharts.Options => {
  const {
    forecast,
    observedData,
    intl,
    currentTimezone,
    overrideVisibleSeries,
    thresholdWave,
    thresholdWindInKnots,
    windUnit,
  } = chartBasis
  const windUnitIsKnots = windUnit === "KT"
  getSeriesVisibility = initialiseSeriesVisibility(overrideVisibleSeries)

  const visibleObs = !!Object.entries(getCompleteSeriesVisibility()).find(
    (elem) =>
      elem[0].startsWith(observedId) &&
      elem[1] &&
      observedData &&
      observedData.find(
        (d) => d.data[elem[0] as OffshoreSeriesKeys] !== undefined
      )
  )

  const minObsDate =
    observedData && moment.min(observedData.map((elem) => elem.valid))
  const minDate = moment.min(...forecast.forecast.map((elem) => elem.valid))
  const xMinVal =
    minObsDate && visibleObs ? moment.min(minObsDate, minDate) : minDate

  let baseOffset = yOffsetWindPlotLine

  const fontSize = "16px"

  let obsPlots = [] as Highcharts.SeriesOptionsType[]
  if (observedData) {
    obsPlots = createObservedOffshorePlots(observedData, intl, windUnitIsKnots)
  }
  let plotLines: XAxisPlotLinesOptions[] = []

  const timeZoneString = currentTimezone === "CET" ? "LT" : currentTimezone

  const timeZonePlotLines: XAxisPlotLinesOptions = generatePlotlineLabel({
    id: "currentTimeZone",
    valid: xMinVal.valueOf(),
    labelOptions: {
      style: {
        fontSize: fontSize,
        color: lunaColors.wind_from_direction,
      },
      align: "left",
      x: xOffsetPlotLine,
      y: 30,
      text: `<div>${timeZoneString}</div>`,
    },
  })
  plotLines.push(timeZonePlotLines)

  if (getWindDirectionVisibillity()) {
    const windPlotLines: XAxisPlotLinesOptions = generatePlotlineLabel({
      id: "wind_from_direction",
      valid: xMinVal.valueOf(),
      labelOptions: {
        style: {
          fontSize: fontSize,
          color: lunaColors.wind_from_direction,
        },
        align: "left",
        x: xOffsetPlotLine,
        y: baseOffset,
        text: `<div>${intl.formatMessage({
          id: "wind",
        })} 10m</div>`,
      },
    })
    baseOffset = baseOffset + barbedOffset
    plotLines.push(windPlotLines)
  }

  if (getWaveDirectionVisibillity()) {
    const wavePlotLines: XAxisPlotLinesOptions = generatePlotlineLabel({
      id: "sea_surface_wave_to_direction",
      valid: xMinVal.valueOf(),
      labelOptions: {
        style: {
          fontSize: fontSize,
          color: lunaColors.sea_surface_wave_significant_height,
        },
        align: "left",
        x: xOffsetPlotLine,
        y: baseOffset,
        text: `<div>${intl.formatMessage({
          id: "sea_surface_wave_to_direction",
        })}</div>`,
      },
    })
    baseOffset = baseOffset + barbedOffset
    plotLines.push(wavePlotLines)
  }

  if (getSwellDirectionVisibillity()) {
    const swellPlotLines: XAxisPlotLinesOptions = generatePlotlineLabel({
      id: "sea_surface_swell_wave_to_direction",
      valid: xMinVal.valueOf(),
      labelOptions: {
        style: {
          fontSize: fontSize,
          color: lunaColors.sea_surface_swell_wave_to_direction,
        },
        align: "left",
        x: xOffsetPlotLine,
        y: baseOffset,
        text: `<div>${intl.formatMessage({
          id: "swell_wave_direction",
        })}</div>`,
      },
    })
    plotLines.push(swellPlotLines)
  }

  let plotBands = plotDayBands(chartBasis)

  if (visibleObs) plotBands.push(plotObservedBands(intl, observedData))

  const marginBottom =
    100 *
      [
        getWindDirectionVisibillity(),
        getWaveDirectionVisibillity(),
        getSwellDirectionVisibillity(),
      ].filter((val) => val).length || 40

  return {
    credits: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    rangeSelector: {
      enabled: false,
    },

    chart: {
      height: 900,
      margin: [20, 150, marginBottom, 150],

      zooming: {
        type: "x",
        mouseWheel: {
          enabled: false,
        },
      },
      resetZoomButton: {
        position: {
          x: 0,
          y: -30,
        },
      },

      style: {
        fontFamily: `"Simplon BP Regular", "sans-serif"`,
        fontSize: "14px",
      },
      events: {
        render: function (event: Event) {
          /**
           * For every chart render/update we place a copy
           * of the chart in the window object so that
           */
          window.exwwChart = cloneDeep(this) as Chart
          if (typeof window.exwwChartChangeHandler === "function") {
            /**
             * This is to let EXWWPreviewPage.tsx await with a promise
             * until the Highcharts chart is actually updated.
             */
            window.exwwChartChangeHandler(this, event)
          }
        },
      },
    },
    tooltip: {
      shared: true,
      style: {
        fontSize: fontSize,
      },
      headerFormat: `<span style="font-size: 16px"><strong>{point.key}</strong></span><br/>`,
    },

    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        connectNulls: true,
        showInNavigator: false,
      },
    },

    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        hour: "%H",
        day: "%H",
      },
      tickPixelInterval: 50,
      crosshair: {
        width: 3,
      },
      labels: {
        autoRotation: undefined,
        style: {
          fontSize: fontSize,
          color: lunaColors.almostBlack,
        },
        formatter: function () {
          return moment.utc(this.value).tz(currentTimezone).format("HH")
        },
      },
      plotLines: plotLines,
      plotBands: plotBands,
    },
    yAxis: [
      {
        startOnTick: true,
        tickInterval: 5,
        height: "70%",
        top: "30%",

        plotLines: [
          {
            value: windUnitIsKnots
              ? thresholdWindInKnots
              : thresholdWindInKnots &&
                convertToMetersPerSecond(thresholdWindInKnots),
            width: 2,
            color: lunaColors.wind_speed_10_meter,
            dashStyle: "LongDash",
            zIndex: 3,
          },
        ],
        labels: {
          format:
            "{value} " +
            intl.formatMessage({
              id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
            }),
          style: {
            color: lunaColors.wind_speed_10_meter,
            fontSize: fontSize,
          },
        },
        opposite: false,
      },
      {
        startOnTick: true,
        height: "70%",
        top: "30%",
        resize: {
          enabled: true,
        },
        labels: {
          format: "{value} m",
          style: {
            color: lunaColors.sea_surface_wave_significant_height,
            fontSize: fontSize,
          },
        },
        opposite: true,
        plotLines: [
          {
            value: thresholdWave,
            dashStyle: "Dash",
            width: 2,
            color: lunaColors.sea_surface_wave_significant_height,
            zIndex: 3,
          },
        ],
      },
      {
        startOnTick: true,
        tickInterval: 2.5,
        top: "30%",
        height: "70%",
        labels: {
          format: "{value} °C",
          style: {
            color: lunaColors.air_temperature,
            fontSize: fontSize,
          },
        },
        opposite: false,
      },
      {
        startOnTick: true,
        height: "70%",
        top: "30%",
        labels: {
          format: "{value} hPa",
          style: {
            color: lunaColors.air_pressure_at_sea_level,
            fontSize: fontSize,
          },
        },
        opposite: true,
      },

      {
        startOnTick: true,
        tickInterval: 2,
        offset: 0,

        height: "25%",
        labels: {
          format: "{value} s",
          style: {
            color: lunaColors.sea_surface_wave_mean_period_TM02,
            fontSize: fontSize,
          },
        },
        opposite: false,
      },
      {
        height: "70%",
        top: "30%",
        visible: false,
        startOnTick: false,
        max: 1,
        min: 0,
      },
    ],

    exporting: {
      buttons: {
        contextButton: { enabled: false },
      },
    },
    series: [
      ...createForecastOffshorePlots(
        forecast as OffshoreForecastData,
        intl,
        windUnitIsKnots
      ),
      ...obsPlots,
      ...getWeatherSymbolPlots(chartBasis),
    ] as Highcharts.SeriesOptionsType[],
  }
}

const getWeatherSymbolPlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      stack: stack.other,
      yAxis: yAxis.symbol,
      marker: {
        enabled: true,
      },
      visible: getSeriesVisibility(OFFSHORE_DATA_ELEMENTS.weather_symbol),
      lineWidth: 0,
      color: lunaColors.transparent,
      name: intl.formatMessage({ id: "weather_symbol" }),
      id: "weather_symbol",
      tooltip: {
        pointFormatter: function () {
          return ""
        },
      },
      dataGrouping: {
        enabled: false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
      data: forecast.forecast.reduce(
        (acc, { valid, data }, currentIndex, currentArray) => {
          // Don't show weather icon at the very edges of graph
          if (currentIndex === 0 || currentIndex === currentArray.length - 1) {
            return acc
          }
          const match = ["0:00", "6:00", "12:00", "18:00"].find(
            (time) => time === valid.format("H:ss")
          )
          if (!match) {
            return acc
          }
          acc.push({
            x: valid.valueOf(),
            y: 0.92,
            marker: {
              height: 40,
              width: 40,
              symbol: `url(/weathericons/${data.weather_symbol_code}.svg)`,
            },
          })
          return acc
        },
        [] as Required<Highcharts.SeriesLineOptions>["data"]
      ),
    },
  ]
}

export const generatePlotlineLabel = ({
  id,
  valid,
  labelOptions,
}: {
  id: string
  valid: number
  labelOptions: XAxisPlotLinesLabelOptions
}) => {
  return {
    color: lunaColors.plotLine,
    dashStyle: "Solid",
    value: valid,
    width: 0,
    id,

    label: {
      verticalAlign: "bottom",
      rotation: 0,
      text: `Some title`,
      ...labelOptions,
    },
  } as XAxisPlotLinesOptions
}

const plotDayBands = ({
  forecast,
  observedData,
  currentTimezone,
  intl,
}: ChartBasis): Highcharts.XAxisPlotBandsOptions[] => {
  const forecastMin = moment.min(...forecast.forecast.map((elem) => elem.valid))
  const forecastMax = moment.max(...forecast.forecast.map((elem) => elem.valid))

  let startDate = forecastMin
  let endDate = forecastMax
  if (observedData) {
    const obsMin = moment.min(...observedData.map((elem) => elem.valid))
    startDate = obsMin < forecastMin ? obsMin : forecastMin
    const obsMax = moment.max(...observedData.map((elem) => elem.valid))
    endDate = forecastMax > obsMax ? forecastMax : obsMax
  }

  const range = moment.range(
    startDate.clone().tz(currentTimezone),
    endDate.clone().tz(currentTimezone)
  )

  return Array.from(range.by("days")).map((day) => {
    return {
      borderColor: lunaColors.veryLight,
      borderWidth: 2,
      color: lunaColors.transparent,
      from: day.startOf("day").valueOf(),
      to: day.endOf("day").valueOf(),
      zIndex: 0,
      label: {
        verticalAlign: "bottom",
        style: {
          fontSize: "16px",
        },
        y: 50,
        text: day.locale(intl.locale).format("dddd Do MMM"),
        formatter: function () {
          const { text } = (this as any).options.label
          // Emphasize name of day, display rest in normal text
          return `<strong>${text
            .split(" ")
            .slice(0, 1)
            .map((str: string) => capitalize(str).slice(0, 3))
            .join()} </strong> ${text.split(" ").slice(1).join(" ")}`
        },
      },
    }
  })
}

export const plotObservedBands = (
  intl: IntlShape,
  observedData: OffshoreDataList[] | undefined
): Highcharts.XAxisPlotBandsOptions => {
  if (observedData) {
    let maxObs = Math.max(...observedData.map((elem) => elem.valid.valueOf()))
    let obsMin = Math.min(...observedData.map((elem) => elem.valid.valueOf()))
    return {
      id: observedId,
      from: obsMin,
      to: maxObs,
      color: lunaColors.observed_plot_bands,
      label: {
        text: intl.formatMessage({ id: "observation_offshore" }),
        align: "center",
        style: {
          fontSize: "16px",
        },
      },
    }
  }
  return {}
}
