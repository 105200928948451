import { useState } from "react"
import {
  loadStringFromLocalStorage,
  addLocalStoragePersistence,
} from "../../../services/localStorageService"

export const useThresholdWave = () => {
  const key = "LunaThresholdWave"
  const [thresholdWave, setThresholdWave] = useState<string>(
    loadStringFromLocalStorage<string>({
      key,
      returnIfNull: "3.5",
    })
  )
  return {
    thresholdWave,
    setThresholdWave: addLocalStoragePersistence({
      key,
      setStateFunc: setThresholdWave,
    }),
  }
}

export const useThresholdCurrent = () => {
  const key = "LunaThresholdCurrent"
  const [thresholdCurrentInMS, setThresholdCurrentInMS] = useState<string>(
    loadStringFromLocalStorage<string>({
      key,
      returnIfNull: "0.2",
    })
  )
  return {
    thresholdCurrentInMS,
    setThresholdCurrentInMS: addLocalStoragePersistence({
      key,
      setStateFunc: setThresholdCurrentInMS,
    }),
  }
}

export const useDepth = () => {
  const key = "LunaDepth"
  const [depth, setDepth] = useState<string>(
    loadStringFromLocalStorage<string>({
      key,
      returnIfNull: "10",
    })
  )
  return {
    depth,
    setDepth: addLocalStoragePersistence({
      key,
      setStateFunc: setDepth,
    }),
  }
}
export const useThresholdWind = () => {
  const key = "LunaThresholdWind"
  const [thresholdWindInKnots, setThresholdWindInKnots] = useState<string>(
    loadStringFromLocalStorage<string>({
      key,
      returnIfNull: "25",
    })
  )
  return {
    thresholdWindInKnots,
    setThresholdWindInKnots: addLocalStoragePersistence({
      key,
      setStateFunc: setThresholdWindInKnots,
    }),
  }
}
