import { useTimezone, useWindUnit } from "@luna/luna-core"
import HighchartsReact from "highcharts-react-official"
import Highcharts, {
  ChartOptions,
  SeriesLineOptions,
  SeriesWindbarbOptions,
  XAxisOptions,
} from "highcharts/highstock"
import BrokenAxis from "highcharts/modules/broken-axis"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import addWindbarb from "highcharts/modules/windbarb"
import * as Moment from "moment"
import MomentTimeZone from "moment-timezone"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import merge from "lodash/merge"
import { highchartsLocalizations } from "./offshoreGraph.localization"
import { Box, FormControl, FormGroup, Stack, Typography } from "@mui/material"
import { OffshoreGraphLegend } from "./OffshoreGraphLegend"
import produce from "immer"
import {
  generatePlotlineLabel,
  offshoreGraphOptions,
  plotObservedBands,
} from "./OffshoreGraphOptions/offshoreGraphOptions"
import {
  OffshoreDataList,
  OverrideVisibilityOptions,
} from "../../../@types/OffshoreGrapOption"
import {
  OFFSHORE_DATA_ELEMENTS,
  lunaColors,
  observedAffix,
  observedId,
  stack,
} from "./OffshoreGraphOptions/graphStaticValues"
import highchartsAccessibility from "highcharts/modules/accessibility"
import moment from "moment"
import {
  barbedOffset,
  xOffsetPlotLine,
  yOffsetWindPlotLine,
} from "./OffshoreGraphOptions/graphOptionsUtils"
import { ObservedBaseOffset } from "./OffshoreGraphOptions/graphOptionsObservedConfig"

highchartsAccessibility(Highcharts)
// Highcharts timezones requires Moment.js to be globally available.
window.moment = Moment
MomentTimeZone()
BrokenAxis(Highcharts)

HighchartsExporting(Highcharts)
HighchartsOfflineExporting(Highcharts)
addWindbarb(Highcharts)

interface State {
  keyCounter: number
  chartOptions?: Highcharts.Options
}

interface Props {
  qubaData: OffshoreReportData["qubaData"]
  observedData: OffshoreDataList[] | undefined
  thresholdWave: number
  thresholdWindInKnots: number
  // Specify which series that should be available.
  overrideVisibleSeries?: OverrideVisibilityOptions
  // Optionally override the generated chart options
  overrideOptions?: Highcharts.Options
}

/**
 * Using forwardRef here so that consumers, can get hold of the Highcharts instance.
 */
const OffshoreGraphHighcharts = React.forwardRef<any, Props>(
  (props, chartRef) => {
    const [data, setData] = useState<State>({
      keyCounter: 0,
    })
    const {
      qubaData: forecast,
      observedData,
      overrideVisibleSeries,
      overrideOptions,
      thresholdWave,
      thresholdWindInKnots,
    } = props
    const intl = useIntl()
    const { currentTimezone } = useTimezone()
    const { windUnit } = useWindUnit()

    const observedKeysSet = new Set()
    observedData?.forEach((elem) => {
      Object.keys(elem.data).forEach((key) => observedKeysSet.add(key))
    })

    const uniqueObsKeysArray = Array.from(observedKeysSet)
    const modelData = forecast?.meta?.units
    const uniqueModelKeys = new Set(Object.keys(modelData))
    const uniqueModelKeysArray = Array.from(uniqueModelKeys)
    const filteredModelKeys = uniqueModelKeysArray.filter((key) => {
      return Object.keys(OFFSHORE_DATA_ELEMENTS).includes(key)
    })
    // Hack to get weather symbol to be displayed in legend. This should be done in a better way.
    filteredModelKeys.push("weather_symbol")

    /**
     * When changing the legend we need to to a lot of checks whether or not the observed data is displayed.
     * TODO: rescale the graph when there are no forecast objects - not very important
     * @param visible
     * @param id
     */
    const handleLegendSelect = (visible: boolean, id: string) => {
      const nextState = produce(data, (draft) => {
        const selectedSeries = draft.chartOptions?.series?.find(
          (series) => series.id === id
        )
        if (selectedSeries) selectedSeries.visible = visible

        let visibleObservations = false

        draft.chartOptions?.series?.forEach((elem) => {
          if (elem.id?.startsWith(observedAffix)) {
            if (elem.visible) {
              visibleObservations = true
            }
          }
        })

        const minDate = moment.min(
          ...forecast.forecast.map((elem) => elem.valid)
        )

        const minObsDate =
          visibleObservations && observedData
            ? moment.min(observedData.map((elem) => elem.valid))
            : undefined

        const xMinValue =
          visibleObservations && minObsDate
            ? moment.min(minObsDate, minDate)
            : minDate

        const fontSize = "16px"
        /**
         * Handle show / hiding the plotlines based on visibillity of observed data.
         * Not a good way of doing this in highcharts at the moment.
         */
        const xAxis = draft.chartOptions?.xAxis as XAxisOptions
        let isObsWindVisible = false
        let isWindVisible = false
        let isObsWaveVisible = false
        let isWaveVisible = false
        let isObsSwellVisible = false
        let isSwellVisible = false

        draft.chartOptions?.series?.forEach((elem) => {
          if (elem.visible) {
            switch (elem.id) {
              case "observed_wind_from_direction":
                isObsWindVisible = true
                break
              case "wind_from_direction":
                isWindVisible = true
                break
              case "observed_sea_surface_wave_from_direction":
                isObsWaveVisible = true
                break
              case "sea_surface_wave_to_direction":
                isWaveVisible = true
                break
              case "observed_sea_surface_swell_wave_to_direction":
                isObsSwellVisible = true
                break
              case "sea_surface_swell_wave_to_direction":
                isSwellVisible = true
                break
            }
          }
        })

        isWindVisible ||= isObsWindVisible
        isWaveVisible ||= isObsWaveVisible
        isSwellVisible ||= isObsSwellVisible

        const chart = draft.chartOptions?.chart as ChartOptions
        chart.marginBottom =
          100 *
            [isWindVisible, isWaveVisible, isSwellVisible].filter((el) => el)
              .length || 40

        let baseOffset = yOffsetWindPlotLine

        xAxis.plotLines = []

        let xCurrentTimeZoneOffest = -60
        if (isWindVisible || isSwellVisible || isWaveVisible) {
          xCurrentTimeZoneOffest = -110
        }

        const timeZoneString =
          currentTimezone === "CET" ? "LT" : currentTimezone

        addPlotLine(
          "currentTimeZone",
          true,
          xCurrentTimeZoneOffest,
          30,
          xMinValue.valueOf(),
          `<div>${timeZoneString}</div>`,
          lunaColors.wind_from_direction
        )

        if (isWindVisible) {
          addPlotLine(
            "wind_from_direction",
            isWindVisible,
            xOffsetPlotLine,
            baseOffset,
            xMinValue.valueOf(),
            `<div>${intl.formatMessage({ id: "wind" })} 10m</div>`,
            lunaColors.wind_from_direction
          )
          baseOffset += barbedOffset
        }
        if (isWaveVisible) {
          addPlotLine(
            "sea_surface_wave_to_direction",
            isWaveVisible,
            xOffsetPlotLine,
            baseOffset,
            xMinValue.valueOf(),
            `<div>${intl.formatMessage({
              id: "sea_surface_wave_to_direction",
            })}</div>`,
            lunaColors.sea_surface_wave_significant_height
          )
          baseOffset += barbedOffset
        }
        if (isSwellVisible) {
          addPlotLine(
            "sea_surface_swell_wave_to_direction",
            isSwellVisible,
            xOffsetPlotLine,
            baseOffset,
            xMinValue.valueOf(),
            `<div style="white-space: pre-line">${intl.formatMessage({
              id: "swell_wave_direction",
            })}</div>`,
            lunaColors.sea_surface_swell_wave_to_direction
          )
        }

        function addPlotLine(
          id: string,
          isVisible: boolean,
          xOffset: number,
          yOffset: number,
          value: number,
          text: string,
          color: string
        ) {
          if (isVisible && !xAxis.plotLines?.find((e) => e.id === id)) {
            xAxis.plotLines?.push(
              generatePlotlineLabel({
                id: id,
                valid: value,
                labelOptions: {
                  style: {
                    fontSize: fontSize,
                    color: color,
                  },
                  align: "left",
                  x: xOffset,
                  y: yOffset,
                  text: text,
                },
              })
            )
          }
        }

        const obsBase = yOffsetWindPlotLine + ObservedBaseOffset

        const yOffsetCalculations: Record<string, number> = {
          observed_sea_surface_wave_from_direction:
            obsBase + (isWindVisible ? barbedOffset : 0),
          sea_surface_wave_to_direction:
            yOffsetWindPlotLine + (isWindVisible ? barbedOffset : 0),
          observed_sea_surface_swell_wave_to_direction: isWindVisible
            ? isWaveVisible
              ? obsBase + 2 * barbedOffset
              : obsBase + barbedOffset
            : isWaveVisible
            ? obsBase + barbedOffset
            : obsBase,
          sea_surface_swell_wave_to_direction: isWindVisible
            ? isWaveVisible
              ? yOffsetWindPlotLine + 2 * barbedOffset
              : yOffsetWindPlotLine + barbedOffset
            : isWaveVisible
            ? yOffsetWindPlotLine + barbedOffset
            : yOffsetWindPlotLine,
        }

        draft.chartOptions?.series?.forEach((elem) => {
          if (elem.visible) {
            const series = elem as SeriesWindbarbOptions
            const offset = yOffsetCalculations[elem.id as string]
            if (offset) series.yOffset = offset
          }
        })

        let obsPlotband = xAxis.plotBands
          ? xAxis.plotBands.find((elem) => elem.id?.startsWith(observedId))
          : undefined

        if (!visibleObservations && obsPlotband) {
          /**
           * If there exists plotbands of an observation when no obs are visible we need to recalculate based of forecast.
           * (this check should probably be more generic, but it works)
           */
          if (xAxis.plotBands) {
            xAxis.plotBands.filter((elem, index, arr) => {
              if (elem.id?.startsWith(observedId)) {
                arr.splice(index, 1)
                return true
              }
              return false
            })
          }
        } else if (visibleObservations && !obsPlotband) {
          if (!observedData) return
          if (xAxis.plotBands)
            xAxis.plotBands.push(plotObservedBands(intl, observedData))
        }
      })
      setData(nextState)
    }

    useEffect(() => {
      Highcharts.setOptions({
        lang: {
          ...highchartsLocalizations.get(intl.locale),
          // Override Norwegian ',' decimal point to be '.'.
          decimalPoint: ".",
        },
        time: {
          timezone: currentTimezone,
        },
        scrollbar: {
          showFull: false,
        },

        plotOptions: {
          abands: {
            label: {
              onArea: false,
            },
          },
        },
      })

      // calculateChartOptions.
      setData((data) => ({
        ...data,
        forecast,
        observedData,
        keyCounter: data.keyCounter + 1,
        chartOptions: merge(
          offshoreGraphOptions({
            overrideVisibleSeries,
            forecast,
            observedData,
            intl,
            currentTimezone,
            windUnit,
            thresholdWave,
            thresholdWindInKnots,
          }),
          overrideOptions || {}
        ),
      }))
      /**
       * Update chart options based on intl/location change. This might not be optimal - but makes
       * it easier to handle updates in graph.
       */
    }, [
      intl,
      currentTimezone,
      windUnit,
      forecast,
      observedData,
      overrideVisibleSeries,
      overrideOptions,
      thresholdWave,
      thresholdWindInKnots,
    ])

    const { chartOptions, keyCounter } = data

    if (!chartOptions) {
      return null
    }

    const legendGroupComponents = (type: string) => {
      return Object.values(stack)
        .filter((g) => !g.endsWith("obs"))
        .map((group) => {
          const legendList = chartOptions.series
            ?.filter((s) => s.stack === group)
            .map((param) => {
              return (
                filteredModelKeys.includes(param?.id || "") && (
                  <OffshoreGraphLegend
                    key={"seriesLegend_" + param.id}
                    name={param.name ?? ""}
                    parameterId={param.id ?? ""}
                    visible={param.visible ?? false}
                    color={
                      (param as SeriesLineOptions).color?.toString() ??
                      "#000000"
                    }
                    handleLegendSelect={handleLegendSelect}
                  />
                )
              )
            })

          const obsList = chartOptions.series
            ?.filter((s) => s.stack === group + "_obs")
            .map((param) => {
              return (
                uniqueObsKeysArray.includes(param?.id || "") && (
                  <OffshoreGraphLegend
                    key={"seriesLegend_" + param.id + "_obs"}
                    name={param.name ?? ""}
                    parameterId={param.id ?? ""}
                    visible={param.visible ?? false}
                    color={
                      (param as SeriesLineOptions).color?.toString() ??
                      "#000000"
                    }
                    handleLegendSelect={handleLegendSelect}
                  />
                )
              )
            })

          if (type === "obs") {
            return (
              <Box sx={{ padding: 1 }} key={group}>
                {observedData && !!obsList?.length && (
                  <Box
                    key={"obsLegendGroup" + group}
                    sx={{
                      width: "230px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">
                      {intl.formatMessage({ id: "observed" })}{" "}
                      {intl.formatMessage({ id: "legend_" + group })}
                    </Typography>
                    {obsList}
                  </Box>
                )}
              </Box>
            )
          }

          return (
            <Box sx={{ padding: 1 }} key={group}>
              <Box
                key={"legendGroup" + group}
                sx={{
                  width: "230px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6">
                  {intl.formatMessage({ id: "legend_" + group })}
                </Typography>
                {legendList}
              </Box>
            </Box>
          )
        })
    }

    return (
      <>
        <HighchartsReact
          key={keyCounter}
          ref={chartRef}
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={chartOptions}
        />
        <FormControl
          sx={{ width: "100%", display: "flex", margin: 0 + "auto" }}
        >
          <FormGroup>
            <Stack
              justifyContent="space-evenly"
              direction={{ xs: "column", sm: "row" }}
            >
              {legendGroupComponents("forecast")}
            </Stack>
            <Stack
              justifyContent="space-evenly"
              direction={{ xs: "column", sm: "row" }}
            >
              {legendGroupComponents("obs")}
            </Stack>
          </FormGroup>
        </FormControl>
      </>
    )
  }
)

export default OffshoreGraphHighcharts
