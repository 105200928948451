import { IntlShape } from "react-intl"
import {
  TimezoneID,
  loadJSONFromLocalStorage,
  roundToDecimals,
  saveInLocalStorage,
  WindUnit,
} from "@luna/luna-core"

import * as Moment from "moment"
import { extendMoment } from "moment-range"
import { convertToKnots } from "@luna/luna-core"
import { capitalize, padStart, round } from "lodash"
import Highcharts, {
  PlotWindbarbOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  XAxisPlotLinesLabelOptions,
  XAxisPlotLinesOptions,
} from "highcharts/highstock"
import HcMore from "highcharts/highcharts-more"

HcMore(Highcharts)
const moment = extendMoment(Moment)

const lunaColors = {
  almostBlack: "#323232",
  transparent: "rgba(255,255,255,0 )",

  current_speed: "#323232",
  current_speed_mean: "#00BCD4",
  current_speed_median: "#2D11D8",
  current_speed_percentile: "#88A0A3",
  current_direction: "#323232",

  threshold: "#D900FD",
  plotLine: "#E0E0E0",
  dayBand: "#555555",
  hourBand: "#E9E9E9",
}

export type SeriesVisibility = {
  current_speed: boolean
  current_speed_mean: boolean
  current_speed_median: boolean
  current_speed_percentile5_95: boolean
  current_direction: boolean
}

type Series = keyof SeriesVisibility

const defaultSeries: SeriesVisibility = {
  current_speed: true,
  current_speed_mean: true,
  current_speed_median: true,
  current_speed_percentile5_95: true,
  current_direction: true,
}

const yAxis = {
  current_speed: 0,
}

export type OverrideVisibilityOptions = Partial<SeriesVisibility> | true

type thresholdCurrentInMS = number | 0.2

type ChartBasis = {
  overrideVisibleSeries?: OverrideVisibilityOptions
  forecast: OffshoreForecast
  intl: IntlShape
  currentTimezone: TimezoneID
  windUnit: WindUnit
  thresholdCurrentInMS: thresholdCurrentInMS
}

const invertDirection = (direction: number) => {
  return (direction + 180) % 360
}

const getCurrentBarbsPlot = ({
  forecast,
  intl,
}: ChartBasis): Array<PlotWindbarbOptions | SeriesLineOptions> => {
  const windData = forecast.forecast.map(({ valid, data }) => {
    return {
      x: moment.utc(valid).valueOf(),
      value: 1,
      direction: invertDirection(data["current_direction"]),
    }
  })

  const windbarbOptions = {
    type: "windbarb",
    yOffset: 80,
    name: intl.formatMessage({ id: "current_direction" }),
    tooltip: {
      pointFormatter: function () {
        return ""
      },
    },
    states: {
      inactive: {
        opacity: 1,
      },
    },
    color: lunaColors.current_direction,
    dataGrouping: {
      enabled: true,
      groupPixelWidth: 40,
    },
    vectorLength: 20,
    lineWidth: 2,
    data: windData,
  }

  return [windbarbOptions]
}

let getSeriesVisibility: (series: Series) => boolean

const plotLineId = "thresholdLine"

const generatePlotlineLabel = ({
  id,
  valid,
  labelOptions,
}: {
  id: string
  valid: number
  labelOptions: XAxisPlotLinesLabelOptions
}) => {
  return {
    color: lunaColors.plotLine,
    dashStyle: "Solid",
    value: valid,
    width: 0,
    id,

    label: {
      verticalAlign: "bottom",
      rotation: 0,
      text: `Some title`,
      ...labelOptions,
    },
  } as XAxisPlotLinesOptions
}

const plotLineOptions = {
  id: plotLineId,
  width: 2,
  color: lunaColors.threshold,
  dashStyle: "ShortDash",
}
export const calculateChartOptions = (
  chartBasis: ChartBasis
): Highcharts.Options => {
  const {
    forecast,
    intl,
    overrideVisibleSeries,
    windUnit,
    thresholdCurrentInMS,
    currentTimezone,
  } = chartBasis

  getSeriesVisibility = initialiseSeriesVisibilityFunc(overrideVisibleSeries)

  const timeseries = forecast.forecast

  const windUnitIsKnots = windUnit === "KT"

  let plotLines: XAxisPlotLinesOptions[] = []
  const baseOffset = 65
  const xOffsetPlotLine = -57

  const fontSize = "16px"

  const minDate = moment.utc(timeseries[0].valid).valueOf()

  const currentPlotLines: XAxisPlotLinesOptions = generatePlotlineLabel({
    id: "current_direction",
    valid: minDate.valueOf(),
    labelOptions: {
      style: {
        fontSize: fontSize,
        color: lunaColors.current_direction,
      },
      align: "left",
      x: xOffsetPlotLine,
      y: baseOffset,
      text: `<div>${intl.formatMessage({
        id: "current_direction",
      })}:</div>`,
    },
  })
  plotLines.push(currentPlotLines)

  return {
    credits: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    rangeSelector: {
      enabled: false,
    },

    chart: {
      height: 500,
      spacingBottom: 20,

      zooming: {
        type: "x",
        mouseWheel: {
          enabled: false,
        },
      },

      style: {
        fontFamily: `"Simplon BP Regular", "sans-serif"`,
        fontSize: "16px",
      },
    },
    tooltip: {
      shared: true,
      style: {
        fontSize: "16px",
      },
      headerFormat: `<span style="font-size: 16px"><strong>{point.key}</strong></span><br/>`,
    },
    scrollbar: { enabled: false },

    title: {
      text: "",
    },
    legend: {
      enabled: true,
      itemMarginTop: 60,
      itemStyle: {
        fontSize: "20px",
      },

      itemHiddenStyle: {
        color: "#808080",
      },
      itemHoverStyle: {
        textDecoration: "underline",
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        events: {
          legendItemClick: (event) => {
            saveSeriesVisibility(
              event.target.options.id as Series,
              event.target.visible
            )
          },
        },
        showInNavigator: false,
      },
    },

    xAxis: {
      type: "datetime",
      offset: 0,
      dateTimeLabelFormats: {
        hour: "%H",
        day: "%H",
      },
      tickPixelInterval: 80,
      crosshair: {
        width: 3,
      },
      tickLength: 0,
      showLastLabel: true,
      min: moment.utc(timeseries[0].valid).valueOf(),
      labels: {
        formatter: function () {
          return this.isFirst
            ? moment.utc(this.value).tz(currentTimezone).format("zz HH")
            : moment.utc(this.value).tz(currentTimezone).format("HH")
        },
      },
      gridLineColor: lunaColors.hourBand,
      gridLineWidth: 1,
      plotLines: plotLines,
      plotBands: [...plotDayBands(chartBasis)],
    },
    yAxis: [
      {
        startOnTick: true,
        tickInterval: 0.2,

        offset: 0,

        title: {
          text: `${intl.formatMessage({
            id: "current_speed",
          })} [${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}]`,

          style: {
            color: lunaColors.current_speed,
            fontWeight: "bold",
            fontSize: "18px",
          },
        },

        plotLines: [
          {
            ...plotLineOptions,
            value: windUnitIsKnots
              ? thresholdCurrentInMS &&
                round(
                  convertToKnots({
                    metersPerSecond: thresholdCurrentInMS,
                  }),
                  1
                )
              : thresholdCurrentInMS,
            dashStyle: "ShortDash", // Fix: Assign a valid DashStyleValue
          },
        ],
        labels: {
          format: "{value}",
          style: {
            color: lunaColors.current_speed,
            fontSize: "16px",
          },
        },
        opposite: false,
      },
    ],

    exporting: {
      buttons: {
        contextButton: { enabled: true },
      },
      sourceWidth: 1920,
      sourceHeight: 1080,
    },
    series: [
      ...getCurrentPlots(chartBasis),
      ...getCurrentBarbsPlot(chartBasis),
      ...getCurrentMeanPlots(chartBasis),
      ...getCurrentMedianPlots(chartBasis),
      ...getCurrentPercentileRangePlots(chartBasis),
      ...getThresholdPlot(chartBasis),
    ] as Highcharts.SeriesOptionsType[],
  }
}

const getThresholdPlot = ({
  intl,
  windUnit,
  thresholdCurrentInMS,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"

  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.threshold,
      marker: {
        enabled: false,
      },
      name: intl.formatMessage({ id: "threshold_value" }),

      dashStyle: "ShortDash",
      events: {
        legendItemClick: function (e) {
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(plotLineId)
          } else {
            this.chart.yAxis[0].addPlotLine({
              ...plotLineOptions,
              value: windUnitIsKnots
                ? round(
                    convertToKnots({ metersPerSecond: thresholdCurrentInMS }),
                    1
                  )
                : thresholdCurrentInMS,
              dashStyle: "ShortDash",
            })
          }
        },
      },
    },
  ]
}

const getCurrentMeanPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"

  if (forecast && forecast.meta && !forecast.meta.epsMembers) {
    return []
  }

  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed_mean,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_mean"),

      name: intl.formatMessage({ id: "current_speed_mean" }),
      id: "current_speed_mean",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y },
          } = this as any
          return `
          <span style="color:${color}">\u25CF</span> ${name}<b>${roundToDecimals(
            y,
            2
          )} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b>`
        },
      },

      lineWidth: 2,
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({ metersPerSecond: data["current_speed_mean"] })
            : data["current_speed_mean"],
        ]
      }),
    },
  ]
}

const getCurrentMedianPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"

  if (forecast && forecast.meta && !forecast.meta.epsMembers) {
    return []
  }

  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed_median,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_median"),

      name: intl.formatMessage({ id: "current_speed_median" }),
      id: "current_speed_median",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y },
          } = this as any
          return `<span style="color:${color}">\u25CF</span> ${name}  <b>${roundToDecimals(
            y,
            2
          )} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b>`
        },
      },

      lineWidth: 2,
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({ metersPerSecond: data["current_speed_median"] })
            : data["current_speed_median"],
        ]
      }),
    },
  ]
}
const getCurrentPercentileRangePlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"

  if (forecast && forecast.meta && !forecast.meta.epsMembers) {
    return []
  }

  return [
    {
      type: "arearange",
      id: "percentileRange",
      yAxis: yAxis.current_speed,
      color: lunaColors.current_speed_percentile,
      fillOpacity: 0.2,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_percentile5_95"),

      name: intl.formatMessage({ id: "current_speed_percentile5_95" }),
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            high,
            low,
          } = this as any
          return `<span style="color:${color}">\u25CF</span> ${name} <b>${roundToDecimals(
            low,
            2
          )} - ${roundToDecimals(high, 3)} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b>`
        },
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({
                metersPerSecond: data["current_speed_percentile5"],
              })
            : data["current_speed_percentile5"],
          windUnitIsKnots
            ? convertToKnots({
                metersPerSecond: data["current_speed_percentile95"],
              })
            : data["current_speed_percentile95"],
        ]
      }),
    },
  ]
}

const getCurrentPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed"),

      name: intl.formatMessage({ id: "current_speed" }),
      id: "current_speed",

      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y: current, direction },
          } = this as any
          const paddedDirection = padStart(direction, 3, "0")
          return `<span style="color:${color}">\u25CF</span> ${name}: <b> ${paddedDirection}° /  ${roundToDecimals(
            current,
            2
          )} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b>`
        },
      },

      lineWidth: 2,

      data: forecast.forecast.map(({ valid, data }) => ({
        x: moment.utc(valid).valueOf(),
        y: windUnitIsKnots
          ? convertToKnots({ metersPerSecond: data["current_speed"] })
          : data["current_speed"],
        direction: data["current_direction"],
      })),
    },
  ]
}

const getCompleteSeriesVisibility = () => {
  return (
    loadJSONFromLocalStorage<SeriesVisibility>({
      key: "LunaOceanCurrentSeriesVisibility",
      returnIfNull: { ...defaultSeries },
    }) || defaultSeries
  )
}

/**
 * Purpose: Make it possible to create a getSeriesVisibility function that lets
 * itself be overwritten.
 * @param overrideVisibleSeries If this is 'true' then use default series. Otherwise allow object to the passed in.
 *
 */
const initialiseSeriesVisibilityFunc = (
  overrideVisibleSeries?: OverrideVisibilityOptions
) => {
  return function getSeriesVisibility(series: Series): boolean {
    if (typeof overrideVisibleSeries === "boolean") {
      return defaultSeries[series]
    } else if (overrideVisibleSeries) {
      return !!overrideVisibleSeries[series]
    }
    const seriesFromStorage = getCompleteSeriesVisibility()
    return seriesFromStorage[series]
  }
}

const saveSeriesVisibility = (series: Series, hidden: boolean) => {
  const seriesFromStorage = getCompleteSeriesVisibility()
  seriesFromStorage[series] = !hidden
  saveInLocalStorage<SeriesVisibility>({
    key: "LunaOceanCurrentSeriesVisibility",
    data: seriesFromStorage,
  })
}

const plotDayBands = ({
  forecast,
  intl,
  currentTimezone,
}: ChartBasis): Highcharts.XAxisPlotBandsOptions[] => {
  const range = moment.range(
    moment.utc(forecast.forecast[0].valid).tz(currentTimezone).startOf("day"),
    moment
      .utc(forecast.forecast[forecast.forecast.length - 1].valid)
      .tz(currentTimezone)
      .endOf("day")
  )
  const daysArray = Array.from(range.by("days"))

  return daysArray.map((day, index) => ({
    borderColor: lunaColors.dayBand,
    borderWidth: 1,
    color: lunaColors.transparent,
    from: day.startOf("day").valueOf(),
    to: day.endOf("day").valueOf(),
    label: {
      verticalAlign: "bottom",
      y: 55,
      style: {
        fontSize: "18px",
        color: lunaColors.almostBlack,
      },
      x: index === daysArray.length - 1 ? -40 : 0,
      text: day.locale(intl.locale).format("dddd Do MMM"),
      formatter: function () {
        const { text } = (this as any).options.label

        // Emphasize name of day, display rest in normal text
        return `<strong>${text
          .split(" ")
          .slice(0, 1)
          .map((str: string) => capitalize(str).slice(0, 3))
          .join()} </strong> ${text.split(" ").slice(1).join(" ")}`
      },
    },
  }))
}

export function getCurrentDirectionVisibillity(): boolean {
  return getSeriesVisibility("current_direction")
}
