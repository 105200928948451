import { SeriesLineOptions, SeriesWindbarbOptions } from "highcharts"
import {
  barbedOffset,
  defaultLineFormat,
  defaultWindbarbFormat,
  getWaveDirectionVisibillity,
  getWindDirectionVisibillity,
  yOffsetWindPlotLine,
} from "./graphOptionsUtils"
import { GraphConfig } from "../../../../@types/OffshoreGrapOption"
import {
  OFFSHORE_DATA_ELEMENTS,
  lunaColors,
  stack,
  staticBarbConfig,
  staticLineConfig,
  yAxis,
} from "./graphStaticValues"

export const forecastOptionsConfig = (): GraphConfig => {
  let baseOffset = yOffsetWindPlotLine
  let yOffsetWind = baseOffset
  baseOffset = getWindDirectionVisibillity()
    ? baseOffset + barbedOffset
    : baseOffset
  let yOffsetWave = baseOffset
  baseOffset = getWaveDirectionVisibillity()
    ? baseOffset + barbedOffset
    : baseOffset
  let yOffsetSwell = baseOffset

  return {
    sea_surface_wave_significant_height: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_significant_height,
        color: lunaColors.sea_surface_wave_significant_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_significant_height,
          directionId: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_to_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_maximum_wave_height: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_maximum_wave_height,
        color: lunaColors.sea_surface_maximum_wave_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_maximum_wave_height,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_significant_swell_wave_height: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_significant_swell_wave_height,
        color: lunaColors.sea_surface_significant_swell_wave_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId:
            OFFSHORE_DATA_ELEMENTS.sea_surface_significant_swell_wave_height,
          directionId:
            OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_wave_mean_period_TM02: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_mean_period_TM02,
        color: lunaColors.sea_surface_wave_mean_period_TM02,
        yAxis: yAxis.period,
        stack: stack.swell,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "s",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_mean_period_TM02,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_wave_peak_period: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_peak_period,
        color: lunaColors.sea_surface_wave_peak_period,
        yAxis: yAxis.period,
        stack: stack.swell,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "s",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_peak_period,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_swell_wave_period: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_period,
        color: lunaColors.sea_surface_swell_wave_period,
        yAxis: yAxis.period,
        stack: stack.swell,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "s",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_period,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_wave_to_direction: {
      series: {
        ...staticBarbConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_to_direction,
        // id: OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction,
        color: lunaColors.sea_surface_wave_to_direction,
        stack: stack.comb_sea,
        vectorLength: 20,
        lineWidth: 1.5,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 20,
          units: [["hour", [3]]],
        },
        yOffset: yOffsetWave,
      } as SeriesWindbarbOptions,
      tools: {
        values: {
          directionId: OFFSHORE_DATA_ELEMENTS.sea_surface_wave_to_direction,
        },
        formatData: defaultWindbarbFormat,
      },
    },
    wind_from_direction: {
      series: {
        ...staticBarbConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_from_direction,
        color: lunaColors.wind_from_direction,
        stack: stack.wind,
        vectorLength: 20,
        lineWidth: 1.5,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 20,
          units: [["hour", [3]]],
        },
        yOffset: yOffsetWind,
      } as SeriesWindbarbOptions,
      tools: {
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_10_meter,
          directionId: OFFSHORE_DATA_ELEMENTS.wind_from_direction,
        },
        formatData: defaultWindbarbFormat,
      },
    },
    wind_speed_10_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_10_meter,
        color: lunaColors.wind_speed_10_meter,
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_10_meter,
          directionId: OFFSHORE_DATA_ELEMENTS.wind_from_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    wind_speed_50_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_50_meter,
        color: lunaColors.wind_speed_50_meter,
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_50_meter,
          directionId: OFFSHORE_DATA_ELEMENTS.wind_from_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    wind_speed_100_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_100_meter,
        color: lunaColors.wind_speed_100_meter,
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_100_meter,
          directionId: OFFSHORE_DATA_ELEMENTS.wind_from_direction_100_meter,
        },
        formatData: defaultLineFormat,
      },
    },
    wind_speed_150_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_150_meter,
        color: lunaColors.wind_speed_150_meter,
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_150_meter,
          directionId: OFFSHORE_DATA_ELEMENTS.wind_from_direction_150_meter,
        },
        formatData: defaultLineFormat,
      },
    },
    wind_speed_of_gust_10_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_10_meter,
        color: lunaColors.wind_speed_of_gust_10_meter,
        dashStyle: "ShortDash",
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_10_meter,
        },
        formatData: defaultLineFormat,
      },
    },

    wind_speed_of_gust_50_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_50_meter,
        color: lunaColors.wind_speed_of_gust_50_meter,
        dashStyle: "ShortDash",
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_50_meter,
        },
        formatData: defaultLineFormat,
      },
    },

    wind_speed_of_gust_100_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_100_meter,
        color: lunaColors.wind_speed_of_gust_100_meter,
        dashStyle: "ShortDash",
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_100_meter,
        },
        formatData: defaultLineFormat,
      },
    },

    wind_speed_of_gust_150_meter: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_150_meter,
        color: lunaColors.wind_speed_of_gust_150_meter,
        dashStyle: "ShortDash",
        yAxis: yAxis.wind,
        stack: stack.wind,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_150_meter,
        },
        formatData: defaultLineFormat,
      },
    },

    sea_surface_swell_wave_to_direction: {
      series: {
        ...staticBarbConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction,
        color: lunaColors.sea_surface_swell_wave_to_direction,
        stack: stack.comb_sea,
        vectorLength: 20,
        lineWidth: 1.5,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 20,
          units: [["hour", [3]]],
        },
        yOffset: yOffsetSwell,
      } as SeriesWindbarbOptions,
      tools: {
        values: {
          directionId:
            OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction,
        },
        formatData: defaultWindbarbFormat,
      },
    },
    air_temperature: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.air_temperature,
        color: lunaColors.air_temperature,
        negativeColor: lunaColors.air_temperature_minus,
        yAxis: yAxis.temp,
        stack: stack.temperature,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.air_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
    dew_point_temperature: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.dew_point_temperature,
        color: lunaColors.dew_point_temperature,
        yAxis: yAxis.temp,
        stack: stack.temperature,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.dew_point_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_temperature: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_temperature,
        color: lunaColors.sea_surface_temperature,
        yAxis: yAxis.temp,
        stack: stack.temperature,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
    air_pressure_at_sea_level: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.air_pressure_at_sea_level,
        color: lunaColors.air_pressure_at_sea_level,
        yAxis: yAxis.pressure,
        stack: stack.other,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "hPa",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.air_pressure_at_sea_level,
        },
        formatData: defaultLineFormat,
      },
    },
    sea_surface_elevation: {
      series: {
        ...staticLineConfig,
        id: OFFSHORE_DATA_ELEMENTS.sea_surface_elevation,
        color: lunaColors.sea_surface_elevation,
        yAxis: yAxis.wave,
        stack: stack.other,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.sea_surface_elevation,
        },
        formatData: defaultLineFormat,
      },
    },
  }
}
