import { IntlShape } from "react-intl"

export const getCurrentRoseOptions = (
  distribution: number[],
  intl: IntlShape,
  minified = false
) => {
  const getTickInterval = (distributionMax: number) => {
    if (minified) {
      return 10
    }
    return distributionMax > 70 ? 20 : 10
  }
  const getAxisMax = (distributionMax: number) => {
    if (minified) {
      return distributionMax > 40 ? Math.ceil(distributionMax / 10) * 10 : 40
    }
    return distributionMax > 70 ? Math.ceil(distributionMax / 10) * 10 : 70
  }
  const distributionMax = Math.max(...distribution)
  const tickInterval = getTickInterval(distributionMax)
  const axisMax = getAxisMax(distributionMax)

  // Remove zeros and duplicates from the distribution
  const unqiueDistribution = Array.from(new Set(distribution)).filter(
    (value) => {
      return value !== 0
    }
  )

  // Zones for the different colors, based on the distribution
  const colorZones = unqiueDistribution
    .map((value) => {
      return {
        value: value + 1,
        color: `rgba(116, 126, 205, ${value / distributionMax})`,
      }
    })
    .sort((a, b) => a.value - b.value)

  return {
    series: [
      {
        name: "Currents",
        data: distribution,
        pointPlacement: 0.5,
        zones: colorZones,
      },
    ],
    chart: {
      polar: true,
      type: "column",
      zooming: {
        mouseWheel: false,
      },
      height: minified ? 110 : null,
      width: minified ? 110 : null,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    pane: {
      scroll: false,
      size: minified ? "100%" : "90%",
      startAngle: -30,
    },
    xAxis: {
      gridLineWidth: minified ? 0 : 1,

      zoomEnabled: false,
      labels: {
        enabled: !minified,
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ): string {
          return this.value.toString()
        },
      },
      categories: minified
        ? ["", "", "", "", "", "", "", "", "", "", "", ""]
        : [
            "345",
            "15",
            "45",
            "75",
            "105",
            "135",
            "165",
            "195",
            "225",
            "255",
            "285",
            "315",
          ],
      tickmarkPlacement: "on",
      gridLineColor: "#555",
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      zoomEnabled: false,
      min: 0,
      showLastLabel: true,
      gridLineDashStyle: "dot",
      gridLineColor: "#555",
      gridLineWidth: minified ? 0 : 1,
      tickInterval: tickInterval,
      max: axisMax,
      labels: {
        enabled: !minified,
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return this.value + "%"
        },
        style: {
          fontSize: "14px",
          fontWeight: "600",
          color: "black",
          textOutline: "1px white", // In case the label is on top of a dark color
        },
      },
    },
    tooltip: {
      enabled: !minified,
      headerFormat: "",
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${intl.formatMessage(
        { id: "probability" }
      )}: <b>{point.y:.f} %</b><br/>`,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 1,
        borderColor: "#555",
      },
    },
  }
}
